// src/HamburgerMenu.js
import React, { useState } from 'react';
import './HamburgerMenu.css';

const HamburgerMenu = ({ setDrawerOpen, drawerOpen }) => {

    const toggleMenu = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <div className="hamburger-container" onClick={toggleMenu}>
            <div className={`line line1 ${drawerOpen ? 'active' : ''}`} />
            <div className={`line line2 ${drawerOpen ? 'active' : ''}`} />
            <div className={`line line3 ${drawerOpen ? 'active' : ''}`} />
        </div>
    );
};

export default HamburgerMenu;
