// Customer.js

class Customer {
    constructor(data) {
        this.id = data.id;
        this.customerName = data.customerName;
        this.invoiceIdentifier = data.invoiceIdentifier;
        this.invoiceReference = data.invoiceReference;
        this.invoiceOperator = data.invoiceOperator;
        this.contactName = data.contactName;
        this.phone = data.phone;
        this.email = data.email;
        this.businessId = data.businessId;
        this.postalCode = data.postalCode;
        this.city = data.city;
        this.address = data.address;
        this.fennoaId = data.fennoaId;
        this.createdAt = new Date(data.createdAt);
        this.updatedAt = new Date(data.updatedAt);
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.customerName;
    }

    getInvoiceIdentifier() {
        return this.invoiceIdentifier;
    }

    getInvoiceReference() {
        return this.invoiceReference;
    }

    getInvoiceOperator() {
        return this.invoiceOperator;
    }

    getContactName() {
        return this.contactName;
    }

    getPhone() {
        return this.phone;
    }

    getEmail() {
        return this.email;
    }

    getBusinessId() {
        return this.businessId;
    }

    getPostalCode() {
        return this.postalCode;
    }

    getCity() {
        return this.city;
    }

    getAddress() {
        return this.address;
    }

    getFennoaId() {
        return this.fennoaId;
    }

    getCreatedAt() {
        return this.createdAt;
    }

    getUpdatedAt() {
        return this.updatedAt;
    }
}

export default Customer;