import React, { useContext, useState } from 'react';
import { Container, TextField, Button, Typography, Alert, Box } from '@mui/material';
import { ApiContext } from '../../Utils/ApiContext';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../Utils/UserContext';

const Login = () => {
    const context = useContext(ApiContext);
    const { ApiCall } = context || {};
    const [userEmail, setUserEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate();
    const { setTempData } = useUser();

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return !re.test(email);
    };



    const handleLogin = (event) => {
        if (event !== undefined && event.key !== "Enter") return;
        setShowError(false);

        ApiCall("POST", "signin", { email: userEmail, password: password })
            .then((response) => handleSuccessfullLogin(response))
            .catch((error) => {
                setShowError(true);
                document.cookie =
                    "token-booking=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            });
    };

    const handleSuccessfullLogin = async (response) => {
        setTempData(userEmail, password);
        if (response.require2FAVerification) {
            console.log("Redirect to verify 2FA view")
            navigate('/verify2fa');
        }
        if (response.require2FASetup) {
            console.log("Redirect to setup 2FA view")
            navigate('/setup2fa');
        }

    };

    return (
        <Box sx={{ padding: "50px" }}>
            <Typography
                sx={{ fontSize: "20px", fontWeight: "700", py: "20px" }}
                color="black"
            >
                Kirjaudu sisään
            </Typography>

            {showError && <Alert severity="error" sx={{ marginBottom: "10px", alignItems: "center", display: "flex" }}>
                <Typography>
                    {"Sähköpostiosoite tai salasana on syötetty väärin."}
                </Typography>
            </Alert>
            }

            <TextField
                sx={{ marginBottom: "10px" }}
                placeholder="Sähköpostiosoite..."
                type={"email"}
                onChange={(text) => setUserEmail(text.target.value)}
                value={userEmail}
                fullWidth
                inputProps={{
                    style: { textAlign: "left", disableUnderline: true },
                }}
            />

            <TextField
                placeholder="Salasana..."
                onChange={(text) => setPassword(text.target.value)}
                value={password}
                type="password"
                fullWidth
                inputProps={{
                    style: { textAlign: "left", disableUnderline: true },
                }}
            />
            <div style={{ flex: 1 }} />

            <Button
                type="submit"
                variant="contained"
                disabled={validateEmail(userEmail) || password === ""}
                onClick={() => handleLogin()}
                sx={{ backgroundColor: "#264166", width: "100%", height: "44px", fontWeight: "700", marginTop: "20px" }}
            >
                <Typography textTransform={"none"}>{"Jatka"}</Typography>
            </Button>
        </Box>
    );
};

export default Login;