import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, List, Typography, Box, useMediaQuery, Tooltip, CircularProgress } from '@mui/material';
import ColorScheme from '../../Styles/ColorScheme';
import { useUser } from '../../Utils/UserContext';

import { ReactComponent as EditIcon } from "../../Assets/pen_edit.svg";
import { ReactComponent as LockIcon } from "../../Assets/lock_icon.svg";
import { ReactComponent as UnlockIcon } from "../../Assets/unlock_icon.svg";

const UserList = () => {
    const { user, loading } = useUser();
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState({ email: '', password: '' });
    const [password, setPassword] = useState('');

    const [loadingUsers, setLoadingUsers] = useState(true);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value });
    };

    const fetchUsers = async () => {
        user.getUsers().then((response) => {
            setUsers(response.users);
            setLoadingUsers(false);
        });
    };

    const handleAddUser = () => {
        setLoadingUsers(true);
        user.createUser(newUser).then((response) => {
            fetchUsers(); // Fetch the updated list of users
            setNewUser({ email: '', password: '' }); // Clear the input fields
        });
    };


    useEffect(() => {
        if (!loading && user) {
            fetchUsers();
        }
    }, [loading, user]);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    return (
        <Container>
            <Box mb={4}>
                <Typography sx={{ marginBottom: "20px" }} variant="h4">Lisää uusi käyttäjä:</Typography>
                <TextField
                    label="Sähköpostiosoite"
                    name="email"
                    value={newUser.email}
                    onChange={handleInputChange}
                    fullWidth
                    margin="dense"
                />
                <TextField
                    label="Salasana"
                    name="password"
                    type="password"
                    value={newUser.password}
                    onChange={handleInputChange}
                    fullWidth
                    margin="dense"
                />
                <Button fullWidth variant="contained" sx={{ backgroundColor: ColorScheme.primary, marginTop: "10px" }} onClick={handleAddUser}>
                    <Typography textTransform={"none"}>
                        Lisää käyttäjä
                    </Typography>
                </Button>
            </Box>
            <Typography sx={{ marginBottom: "10px" }} variant="h4">Olemassa olevat käyttäjät:</Typography>
            <List>
                {!loadingUsers ? <>

                    <Box sx={{ width: "100%", backgroundColor: ColorScheme.border, height: "1px" }} />
                    {users.map((existingUser, index) => (
                        <React.Fragment key={index}>
                            <Box sx={{ flexDirection: "row", display: "flex", width: "100%", justifyContent: "space-between", }}>
                                <Box sx={{ flexDirection: "row", display: "flex", alignItems: "center", py: "10px" }}>
                                    <Box sx={{ width: "50px", height: "50px", backgroundColor: existingUser.getTwoFactorEnabled() ? ColorScheme.green_opacity : ColorScheme.red_opacity, borderRadius: "4px", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
                                        {existingUser.getTwoFactorEnabled() ? <LockIcon height={"20px"} stroke={ColorScheme.green} /> : <UnlockIcon height={"20px"} stroke={ColorScheme.red} />}
                                        <Typography variant='h6'>{"2FA"}</Typography>
                                    </Box>
                                    <Box sx={{ flexDirection: "column", display: "flex", alignItems: "flex-start", justifyContent: "center", marginLeft: "10px" }}>
                                        <Typography variant='h4'>
                                            {existingUser.getEmail()}
                                        </Typography>
                                        <Typography variant='body2'>
                                            {"Lisätty: " + existingUser.getCreatedAt()}
                                        </Typography>
                                    </Box>


                                </Box>

                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", cursor: "pointer" }}>
                                    <Tooltip
                                        title={
                                            <Box padding={"20px"}>
                                                <TextField
                                                    label="Uusi salasana"
                                                    type="password"
                                                    value={password}
                                                    onChange={handlePasswordChange}
                                                    fullWidth
                                                />
                                                <Button
                                                    disabled={password.length < 8}
                                                    onMouseDown={(event) => {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                        user.changePassword(existingUser.getId(), { password: password })
                                                        setPassword('');
                                                    }}
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ backgroundColor: ColorScheme.primary, marginTop: "10px" }}
                                                >
                                                    <Typography textTransform={"none"}>Vaihda salasana</Typography>
                                                </Button>
                                            </Box>
                                        }
                                    >
                                        <EditIcon height={"20px"} stroke={ColorScheme.primary} />
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Box sx={{ width: "100%", backgroundColor: ColorScheme.border, height: "1px" }} />
                        </React.Fragment>
                    ))}
                </> : <Box sx={{ height: "100%", width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
                    <CircularProgress sx={{ color: ColorScheme.primary }} />
                </Box>}
            </List>
        </Container>
    );
};

export default UserList;