import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, List, ListItem, ListItemText, TextField, Box, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, Tooltip, CircularProgress } from '@mui/material';
import { Button, ButtonGroup } from '@mui/material';
import ColorScheme from '../../Styles/ColorScheme';
import { ReactComponent as ArrowUp } from "../../Assets/arrow_up.svg";
import { ReactComponent as ArrowDown } from "../../Assets/arrow_down.svg";
import { ReactComponent as EditIcon } from "../../Assets/pen_edit.svg";
import { useUser } from '../../Utils/UserContext';
import ContractDialog from './Contract';


const ContractList = () => {
    const [contracts, setContracts] = useState([]);
    const { user, loading } = useUser();
    const [expanded, setExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'customerName', direction: 'asc' });
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedContract, setSelectedContract] = useState(null);

    const [invoicePage, setInvoicePage] = useState(1);

    const [contractRows, setContractRows] = useState({});
    const [contractInvoices, setContractInvoices] = useState({});
    const [totalInvoicePages, setTotalInvoicePages] = useState({})
    const [loadingContracts, setLoadingContracts] = useState(false);
    const [loadingInvoices, setLoadingInvoices] = useState(false);
    const [loadingRows, setLoadingRows] = useState(false);

    const isTabletOrBelow = useMediaQuery('(max-width:768px)');

    const handleAccordionChange = async (contractId) => {
        setInvoicePage(1);
        setLoadingInvoices(true);
        setLoadingRows(true);
        if (expanded === contractId) {
            setExpanded(false);
            setLoadingInvoices(false);
            setLoadingRows(false);
        } else {
            setExpanded(contractId);
            if (!contractRows[contractId] || !contractInvoices[contractId]) {
                try {
                    const contract = contracts.find(c => c.getId() === contractId);
                    const rows = await contract.getRows();
                    const invoices = await contract.getInvoices(invoicePage); // Fetch invoices
                    setContractRows(prevState => ({ ...prevState, [contractId]: rows }));
                    setContractInvoices(prevState => ({ ...prevState, [contractId]: invoices.invoices })); // Update state with invoices
                    setLoadingInvoices(false);
                    setLoadingRows(false);
                } catch (error) {
                    console.error("Failed to fetch rows or invoices:", error);
                    setLoadingInvoices(false);
                    setLoadingRows(false);
                }
            }
            else {
                setLoadingInvoices(false);
                setLoadingRows(false);
            }
        }
    };

    useEffect(() => {
        if (expanded) {
            setLoadingInvoices(true);
            const contract = contracts.find(c => c.getId() === expanded);
            contract.getInvoices(invoicePage)
                .then((response) => {
                    setContractInvoices(prevState => ({ ...prevState, [contract.getId()]: response.invoices }));
                    setTotalInvoicePages(prevState => ({ ...prevState, [contract.getId()]: response.totalPages }));
                    setLoadingInvoices(false);
                })
                .catch((error) => {
                    console.error("Failed to fetch invoices:", error);
                    setLoadingInvoices(false);
                });

        }
    }, [invoicePage]);


    useEffect(() => {
        setExpanded(false);
        if (!loading && user) {
            fetchContracts();
        }
    }, [page]);

    useEffect(() => {
        setPage(1);
        setExpanded(false);
        if (!loading && user) {
            fetchContracts();
        }
    }, [sortConfig]);

    useEffect(() => {
        setPage(1);
        setExpanded(false);
        if (!loading && user) {
            const timeoutId = setTimeout(() => {
                fetchContracts(searchTerm);
            }, 1000);

            return () => clearTimeout(timeoutId);
        }
    }, [searchTerm]);

    const fetchContracts = () => {
        setLoadingContracts(true);
        const { key, direction } = sortConfig;
        user.getContracts({ sortField: key, sortOrder: direction, searchTerm, page })
            .then((response) => {
                setContracts(response.contracts);
                setTotalPages(response.totalPages);
                setLoadingContracts(false);
            })
            .catch((error) => {
                console.log(error);
                setLoadingContracts(false);
            });
    };

    const handlePageChange = (event, value) => {
        setExpanded(false);
        setPage(value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSort = (property) => {
        let direction = 'asc';
        if (sortConfig.key === property && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key: property, direction });
    };

    const handleDialogOpen = (contract = null) => {
        setSelectedContract(contract);
        setDialogOpen(true);
    };

    const handleDialogClose = async () => {
        setDialogOpen(false);

        if (selectedContract) {
            setLoadingInvoices(true);
            setLoadingRows(true);
            try {
                // Fetch updated contract data
                const updatedContract = await user.getContractById(selectedContract.getId());
                const updatedRows = await updatedContract.getRows();
                const updatedInvoices = await updatedContract.getInvoices(invoicePage);

                // Update contracts state
                setContracts(prevContracts => prevContracts.map(contract =>
                    contract.getId() === updatedContract.getId() ? updatedContract : contract
                ));

                // Update contractRows state
                setContractRows(prevState => ({ ...prevState, [updatedContract.getId()]: updatedRows }));

                // Update contractInvoices state
                setContractInvoices(prevState => ({ ...prevState, [updatedContract.getId()]: updatedInvoices.invoices }));
                setLoadingInvoices(false);
                setLoadingRows(false);
            } catch (error) {
                console.error("Failed to fetch updated contract data:", error);
                setLoadingInvoices(false);
                setLoadingRows(false);
            }
        }

        setSelectedContract(null);
    };

    const handleSave = () => {
        fetchContracts();
    };


    const renderInvoiceRows = (invoice) => {
        return (
            <Box>
                {invoice.getInvoiceRows().map((row, index) => (
                    <Typography color={ColorScheme.black} key={index} variant='body2'>
                        {row.description}: {row.price} €
                    </Typography>
                ))}
            </Box>
        );
    };

    return (
        <Container sx={{ paddingBottom: "20px", paddingTop: "10px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
                <Typography variant="h2" gutterBottom>
                    Sopimukset
                </Typography>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: ColorScheme.primary, color: ColorScheme.white, textTransform: "none" }}
                    onClick={() => handleDialogOpen()}
                >
                    <Typography variant='h6'>
                        {"Lisää sopimus"}
                    </Typography>
                </Button>
            </Box>


            <TextField
                size='small'
                sx={{ backgroundColor: ColorScheme.search, borderRadius: "4px", }}
                label="Etsi"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchTerm}
                onChange={handleSearchChange}
            />

            <Box sx={{ width: "100%" }}>
                <ButtonGroup fullWidth variant="outlined" sx={{
                    my: "10px", ".MuiButtonGroup-grouped": {
                        borderColor: ColorScheme.border,
                    },
                }}>
                    <Button
                        onClick={() => handleSort('customerName')}
                        sx={{
                            borderColor: "red",
                            backgroundColor: sortConfig.key === 'customerName' ? ColorScheme.primary : ColorScheme.lightGrey,
                            color: sortConfig.key === 'customerName' ? ColorScheme.white : ColorScheme.primary,
                            '&:hover': {
                                backgroundColor: sortConfig.key === 'customerName' ? ColorScheme.primary : 'transparent',
                            },
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant='h6' textTransform={"none"}>
                            Nimi
                        </Typography>
                        {sortConfig.key === 'customerName' ? (
                            sortConfig.direction === 'asc' ? <ArrowUp height={15} stroke={ColorScheme.white} /> : <ArrowDown height={15} stroke={ColorScheme.white} />
                        ) : <ArrowUp height={15} stroke={ColorScheme.primary} />}

                    </Button>
                    <Button
                        onClick={() => handleSort('nextInvoicesDate')}
                        sx={{
                            backgroundColor: sortConfig.key === 'nextInvoicesDate' ? ColorScheme.primary : ColorScheme.lightGrey,
                            color: sortConfig.key === 'nextInvoicesDate' ? ColorScheme.white : ColorScheme.primary,
                            '&:hover': {
                                backgroundColor: sortConfig.key === 'nextInvoicesDate' ? ColorScheme.primary : 'transparent',
                            },
                        }}
                    >
                        <Typography variant='h6' textTransform={"none"}>
                            Lasku
                        </Typography>
                        {sortConfig.key === 'nextInvoicesDate' ? (
                            sortConfig.direction === 'asc' ? <ArrowUp height={15} stroke={ColorScheme.white} /> : <ArrowDown height={15} stroke={ColorScheme.white} />
                        ) : <ArrowUp height={15} stroke={ColorScheme.primary} />}

                    </Button>
                    <Button
                        onClick={() => handleSort('startingDate')}
                        sx={{
                            backgroundColor: sortConfig.key === 'startingDate' ? ColorScheme.primary : ColorScheme.lightGrey,
                            color: sortConfig.key === 'startingDate' ? ColorScheme.white : ColorScheme.primary,
                            '&:hover': {
                                backgroundColor: sortConfig.key === 'startingDate' ? ColorScheme.primary : 'transparent',
                            },
                        }}
                    >
                        <Typography variant='h6' textTransform={"none"}>
                            Alkanut
                        </Typography>
                        {sortConfig.key === 'startingDate' ? (
                            sortConfig.direction === 'asc' ? <ArrowUp height={15} stroke={ColorScheme.white} /> : <ArrowDown height={15} stroke={ColorScheme.white} />
                        ) : <ArrowUp height={15} stroke={ColorScheme.primary} />}
                    </Button>
                </ButtonGroup>
            </Box>
            <Box sx={{ border: 1, borderColor: ColorScheme.border, borderRadius: "4px", backgroundColor: ColorScheme.lightGrey }}>
                {!loadingContracts ? <List sx={{ p: "5px" }}>
                    {contracts.map((contract) => {
                        return <ListItem key={contract.getId()} sx={{ p: 0 }} >
                            <Accordion
                                elevation={0}
                                sx={{ flexDirection: "column", width: "100%", backgroundColor: expanded === contract.getId() ? ColorScheme.white : ColorScheme.lightGrey }}
                                expanded={expanded === contract.getId()}
                                onChange={() => handleAccordionChange(contract.getId())}>
                                <AccordionSummary sx={{ width: "100%", paddingRight: 0 }}>
                                    <Box sx={{ flexDirection: "row", display: "flex", width: "100%", justifyContent: "space-between" }}>
                                        <Box sx={{ flexDirection: "column", display: "flex", alignItems: "flex-start", justifyContent: "center", width: !isTabletOrBelow ? "25%" : "40%" }}>
                                            <Typography variant='h4'>
                                                {contract.getCustomer().getName()}
                                            </Typography>
                                            <Typography variant='body2'>
                                                {contract.getCustomer().getAddress() + ", " + contract.getCustomer().getPostalCode() + " " + contract.getCustomer().getCity()}
                                            </Typography>
                                        </Box>
                                        {!isTabletOrBelow && (<Box sx={{ flexDirection: "row", alignItems: "center", display: "flex", alignItems: "center", justifyContent: "center", width: "50%" }}>
                                            <Box sx={{ marginRight: "2px", padding: "5px", flexDirection: "column", display: "flex", alignItems: "center", width: "25%", borderRadius: "4px", border: "1px solid", borderColor: ColorScheme.border }}>
                                                <Typography variant='h6' sx={{
                                                    display: '-webkit-box',
                                                    overflow: 'hidden',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 1,
                                                }}>
                                                    {"Sopimus alkanut"}
                                                </Typography>
                                                <Typography variant='body1'>
                                                    {new Date(contract.getStartingDate()).toLocaleDateString()}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ marginLeft: "2px", padding: "5px", flexDirection: "column", display: "flex", alignItems: "center", width: "25%", borderRadius: "4px", border: "1px solid", borderColor: ColorScheme.border }}>
                                                <Typography variant='h6' sx={{
                                                    display: '-webkit-box',
                                                    overflow: 'hidden',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 1,
                                                    textAlign: "center"
                                                }}>
                                                    {"Seuraava laskutuspäivä"}
                                                </Typography>
                                                <Typography variant='body1'>
                                                    {new Date(contract.getNextInvoicesDate()).toLocaleDateString()}
                                                </Typography>
                                            </Box>
                                        </Box>)}
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: !isTabletOrBelow ? "25%" : "60%", justifyContent: "flex-end" }}>
                                            <Box sx={{ flexDirection: "column", display: "flex", alignItems: "flex-end" }}>
                                                <Box sx={{ flexDirection: "row", display: "flex", justifyContent: "space-between" }}>
                                                    <Typography variant='h6' textAlign={"right"}>
                                                        {contract.getEndingDate() == null ? "Toistaiseksi voimassa" : (new Date(contract.getEndingDate()) < new Date() ? ("Päättynyt " + new Date(contract.getEndingDate()).toLocaleDateString()) : "Päättyy " + new Date(contract.getEndingDate()).toLocaleDateString())}
                                                    </Typography>
                                                    <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                                                        <Box
                                                            sx={{
                                                                width: 10,
                                                                height: 10,
                                                                borderRadius: '50%',
                                                                backgroundColor: contract.getEndingDate() == null ? ColorScheme.green : (new Date(contract.getEndingDate()) < new Date() ? ColorScheme.red : ColorScheme.yellow),
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>

                                                <Typography variant='body1'>
                                                    {parseFloat(contract.getTotalPrice().toFixed(2)) + " €" + " / " + (contract.getInvoicingInterval() == 1 ? "" : contract.getInvoicingInterval()) + " kk"}
                                                </Typography>

                                            </Box>
                                            <Button sx={{ mx: "15px", padding: 0, minWidth: 0 }} onClick={(event) => {
                                                event.stopPropagation()
                                                handleDialogOpen(contract)
                                            }} >
                                                <EditIcon height={"20px"} stroke={ColorScheme.primary} />
                                            </Button>
                                        </Box>

                                    </Box>


                                </AccordionSummary>
                                <AccordionDetails sx={{ display: "flex", flexDirection: isTabletOrBelow ? "column" : "row", justifyContent: "space-between" }}>
                                    <Box sx={{ width: isTabletOrBelow ? "100%" : "48%" }}>
                                        {isTabletOrBelow && <Typography variant='h4' sx={{ marginBottom: "20px" }}>
                                            {"Sopimuksen rivit"}
                                        </Typography>}
                                        {!loadingRows ? (contractRows[contract.getId()] && contractRows[contract.getId()].map((row, index) => {
                                            return <Box key={row.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, borderTop: index == 0 && '1px solid ' + ColorScheme.border, borderBottom: '1px solid ' + ColorScheme.border }}>
                                                <Box sx={{ width: "90%", flexDirection: "row", display: "flex" }}>
                                                    <Box sx={{ marginRight: "10px", border: "1px solid " + ColorScheme.border, borderRadius: "4px", padding: "10px", backgroundColor: ColorScheme.lightGrey, flexDirection: "column", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                        <Typography variant='body2'>
                                                            {row.getAmount() + " " + row.getUnit()}
                                                        </Typography>
                                                        <Typography variant='h6'>
                                                            {row.getUnitPrice() + " €"}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                                            {row.getDescription()}
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            {"Alennus: " + row.getDiscountPercentage() + " %"}
                                                        </Typography>
                                                    </Box>

                                                </Box>
                                                <Box sx={{ textAlign: 'right', width: "10%" }}>
                                                    <Typography variant='h6'>
                                                        {row.getPrice()} €
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        })) : <Box sx={{ width: "100%", padding: "20px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                            <CircularProgress sx={{ color: ColorScheme.primary }} />
                                        </Box>
                                        }
                                    </Box>
                                    <Box sx={{ width: isTabletOrBelow ? "100%" : "48%", borderRadius: "4px" }}>
                                        {isTabletOrBelow && <Typography variant='h4' sx={{ marginBottom: "20px", marginTop: "30px" }}>
                                            {"Lähetetyt laskut"}
                                        </Typography>}
                                        {!loadingInvoices ? (contractInvoices[contract.getId()] && contractInvoices[contract.getId()].map(invoice => {
                                            return <Tooltip key={invoice.id} placement='top' followCursor title={renderInvoiceRows(invoice)} >
                                                <Box sx={{ display: 'flex', marginBottom: "5px", borderRadius: "4px", justifyContent: 'space-between', alignItems: 'center', p: 1, backgroundColor: invoice.getPaymentStatus() ? ColorScheme.green_opacity : ColorScheme.red_opacity }}>
                                                    <Box>
                                                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                                            {invoice.getTotalDue() + " €"}
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            {invoice.getDueDate() ? "Eräpäivä " + new Date(invoice.getDueDate()).toLocaleDateString() : "Eräpäivä ei määritelty"}
                                                        </Typography>

                                                    </Box>
                                                    <Box sx={{ textAlign: 'right' }}>
                                                        <Typography variant='h6' color={invoice.getPaymentStatus() ? ColorScheme.green : ColorScheme.red}>
                                                            {invoice.getPaymentStatus() ? "Maksettu" : "Maksamatta"}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Tooltip>

                                        })) : <Box sx={{ width: "100%", padding: "20px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                            <CircularProgress sx={{ color: ColorScheme.primary }} />
                                        </Box>

                                        }
                                        {totalInvoicePages[contract.getId()] > 1 && (
                                            <>
                                                <ButtonGroup fullWidth variant="outlined" sx={{
                                                    ".MuiButtonGroup-grouped": {
                                                        borderColor: ColorScheme.border,
                                                    },
                                                }}>
                                                    {(() => {
                                                        const pages = Array.from(new Set([
                                                            ...Array.from({ length: 3 }, (_, i) => invoicePage - 1 + i), // current page ± 1
                                                            1, // first page
                                                            totalInvoicePages[contract.getId()] // last page
                                                        ]))
                                                            .filter(pageNr => pageNr > 0 && pageNr <= totalInvoicePages[contract.getId()])
                                                            .sort((a, b) => a - b);

                                                        const result = [];
                                                        for (let i = 0; i < pages.length; i++) {
                                                            result.push(pages[i]);
                                                            if (i < pages.length - 1 && pages[i + 1] > pages[i] + 1) {
                                                                result.push("...");
                                                            }
                                                        }
                                                        return result;
                                                    })().map((pageNr, index) => (
                                                        pageNr === "..." ? (
                                                            <Button sx={{
                                                                backgroundColor: pageNr === page ? ColorScheme.primary : ColorScheme.lightGrey,
                                                                color: pageNr === page ? ColorScheme.white : ColorScheme.primary,
                                                                '&:hover': {
                                                                    backgroundColor: pageNr === page ? ColorScheme.primary : 'transparent',
                                                                },
                                                            }} key={`ellipsis-${index}`}>
                                                                <Typography textTransform={"none"} variant='body2'>
                                                                    ...
                                                                </Typography>
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                key={pageNr}
                                                                onClick={(event) => setInvoicePage(pageNr)}
                                                                sx={{
                                                                    backgroundColor: pageNr === invoicePage ? ColorScheme.primary : ColorScheme.lightGrey,
                                                                    color: pageNr === invoicePage ? ColorScheme.white : ColorScheme.primary,
                                                                    '&:hover': {
                                                                        backgroundColor: pageNr === invoicePage ? ColorScheme.primary : 'transparent',
                                                                    },
                                                                }}
                                                            >
                                                                <Typography textTransform={"none"} variant='body2'>
                                                                    {pageNr}
                                                                </Typography>
                                                            </Button>
                                                        )
                                                    ))}
                                                </ButtonGroup>

                                                <ButtonGroup fullWidth variant="outlined" sx={{
                                                    ".MuiButtonGroup-grouped": {
                                                        borderColor: ColorScheme.border,
                                                    },
                                                    marginTop: 2 // Add some margin to separate the groups
                                                }}>
                                                    <Button
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setInvoicePage(invoicePage - 1);
                                                        }}
                                                        disabled={invoicePage === 1}
                                                        sx={{
                                                            backgroundColor: ColorScheme.lightGrey,
                                                            color: ColorScheme.primary,
                                                            '&:hover': {
                                                                backgroundColor: 'transparent',
                                                            },
                                                        }}
                                                    >
                                                        <Typography textTransform={"none"} variant='body2'>
                                                            Edellinen
                                                        </Typography>
                                                    </Button>

                                                    <Button
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setInvoicePage(invoicePage + 1);
                                                        }}
                                                        disabled={invoicePage === totalInvoicePages[contract.getId()]}
                                                        sx={{
                                                            backgroundColor: ColorScheme.lightGrey,
                                                            color: ColorScheme.primary,
                                                            '&:hover': {
                                                                backgroundColor: 'transparent',
                                                            },
                                                        }}
                                                    >
                                                        <Typography textTransform={"none"} variant='body2'>
                                                            Seuraava
                                                        </Typography>
                                                    </Button>
                                                </ButtonGroup>
                                            </>
                                        )}
                                    </Box>

                                </AccordionDetails>
                            </Accordion>
                        </ListItem>
                    })}
                </List> : <Box sx={{ width: "100%", padding: "20px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                    <CircularProgress sx={{ color: ColorScheme.primary }} />
                </Box>}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", mt: "20px", flexDirection: "column" }}>
                <ButtonGroup fullWidth variant="outlined" sx={{
                    ".MuiButtonGroup-grouped": {
                        borderColor: ColorScheme.border,
                    },
                }}>
                    {(() => {
                        const pages = Array.from(new Set([
                            ...Array.from({ length: 3 }, (_, i) => page - 1 + i), // current page ± 1
                            1, // first page
                            totalPages // last page
                        ]))
                            .filter(pageNr => pageNr > 0 && pageNr <= totalPages)
                            .sort((a, b) => a - b);

                        const result = [];
                        for (let i = 0; i < pages.length; i++) {
                            result.push(pages[i]);
                            if (i < pages.length - 1 && pages[i + 1] > pages[i] + 1) {
                                result.push("...");
                            }
                        }
                        return result;
                    })().map((pageNr, index) => (
                        pageNr === "..." ? (
                            <Button sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: pageNr === page ? ColorScheme.primary : ColorScheme.lightGrey,
                                color: pageNr === page ? ColorScheme.white : ColorScheme.primary,
                                '&:hover': {
                                    backgroundColor: pageNr === page ? ColorScheme.primary : 'transparent',
                                },
                            }} key={`ellipsis-${index}`}>
                                <Typography textTransform={"none"} variant='body1'>
                                    ...
                                </Typography>
                            </Button>
                        ) : (
                            <Button
                                key={pageNr}
                                onClick={(event) => handlePageChange(event, pageNr)}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: pageNr === page ? ColorScheme.primary : ColorScheme.lightGrey,
                                    color: pageNr === page ? ColorScheme.white : ColorScheme.primary,
                                    '&:hover': {
                                        backgroundColor: pageNr === page ? ColorScheme.primary : 'transparent',
                                    },
                                }}
                            >
                                <Typography textTransform={"none"} variant='body1'>
                                    {pageNr}
                                </Typography>
                            </Button>
                        )
                    ))}
                </ButtonGroup>

                <ButtonGroup fullWidth variant="outlined" sx={{
                    ".MuiButtonGroup-grouped": {
                        borderColor: ColorScheme.border,
                    },
                    marginTop: 2 // Add some margin to separate the groups
                }}>
                    <Button
                        onClick={(event) => handlePageChange(event, page - 1)}
                        disabled={page === 1}
                        sx={{
                            minWidth: "200px",
                            backgroundColor: ColorScheme.lightGrey,
                            color: ColorScheme.primary,
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        <Typography textTransform={"none"} variant='body1'>
                            Edellinen
                        </Typography>
                    </Button>

                    <Button
                        onClick={(event) => handlePageChange(event, page + 1)}
                        disabled={page === totalPages}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: ColorScheme.lightGrey,
                            color: ColorScheme.primary,
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        <Typography textTransform={"none"} variant='body1'>
                            Seuraava
                        </Typography>
                    </Button>
                </ButtonGroup>
            </Box>


            <ContractDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                contract={selectedContract}
                onSave={handleSave}
            />

        </Container>
    );
};

export default ContractList;