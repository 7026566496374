import React, { useEffect, useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import TextField from '@mui/material/TextField';
import PdfDocument from './PdfRender';
import { Box, useMediaQuery } from '@mui/system';
import { Button, Grid, IconButton, List, ListItem, Typography } from '@mui/material';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ColorScheme from '../../Styles/ColorScheme';
import { ReactComponent as RemoveIcon } from "../../Assets/remove_icon.svg";

function Offer() {

    const [customer, setCustomer] = useState("");
    const [address, setAddress] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [productAmount, setProductAmount] = useState(1);
    const [serviceAmount, setServiceAmount] = useState(1);
    const [products, setProducts] = useState([{ name: "", amount: "", price: "" }]);
    const [services, setServices] = useState([{ name: "", price: "" }]);

    const [contactName, setContactName] = useState("");
    const [contactPhone, setContactPhone] = useState("");

    const [letterTitle, setLetterTitle] = useState("");
    const [letterDescription, setLetterDescription] = useState("");

    const [access, setAccess] = useState(false);
    const [booking, setBooking] = useState(false);
    const [keymanagement, setKeymanagement] = useState(false);
    const [charging, setCharging] = useState(false);
    const [summary, setSummary] = useState(false);
    const [camera, setCamera] = useState(false);

    const [accessDescription, setAccessDescription] = useState('');
    const [cameraDescription, setCameraDescription] = useState('');
    const [keymanagementDescription, setKeymanagementDescription] = useState('');
    const [bookingDescription, setBookingDescription] = useState('');
    const [chargingDescription, setChargingDescription] = useState('');

    const isTabletOrBelow = useMediaQuery('(max-width:768px)');

    function handleProducts(index, type, value) {
        let productsCopy = [...products];
        productsCopy[index][type] = value;
        setProducts(productsCopy);
    }

    function handleServices(index, type, value) {
        let servicesCopy = [...services];
        servicesCopy[index][type] = value;
        setServices(servicesCopy);
    }

    function handleProductAmount(amount) {
        if (amount === 1) {
            setProductAmount(productAmount + 1);
            setProducts([...products, { name: "", amount: "", price: "" }]);
        } else {
            if (productAmount > 0) {
                setProductAmount(productAmount - 1);
                setProducts(products.slice(0, -1));
            }
        }
    }

    function handleServiceAmount(amount) {
        if (amount === 1) {
            setServiceAmount(serviceAmount + 1);
            setServices([...services, { name: "", amount: "", price: "" }]);
        } else {
            if (serviceAmount > 0) {
                setServiceAmount(serviceAmount - 1);
                setServices(services.slice(0, -1));
            }
        }
    }


    return (
        <Box sx={{ padding: "10px", flexDirection: isTabletOrBelow ? "column" : "row", display: "flex", alignItems: "center", justifyContent: "space-between", height: "100%" }}>
            <Box sx={{ width: isTabletOrBelow ? "100%" : "49%", height: "100%" }}>
                <List
                    dense={true}
                    style={{
                        overflow: "auto",
                        maxHeight: "100%",
                        backgroundColor: ColorScheme.white,
                        borderRadius: 2
                    }}
                >

                    {summary && <>
                        <ListItem>
                            <TextField
                                size="small"
                                label={<Typography>Saatteen otsikko</Typography>}
                                style={{ width: "100%" }}
                                value={letterTitle}
                                onChange={(newValue) =>
                                    setLetterTitle(newValue.target.value)
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <TextField
                                size="small"
                                multiline
                                label={<Typography>Tarjouksen kuvaus</Typography>}
                                style={{ width: "100%" }}
                                value={letterDescription}
                                onChange={(newValue) =>
                                    setLetterDescription(newValue.target.value)
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <FormGroup sx={{ width: "100%" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked={false}
                                            value={access}
                                            onChange={(e) => setAccess(e.target.checked)}
                                        />
                                    }
                                    label="Kulunvalvontajärjestelmä"
                                />
                                {access && (
                                    <TextField
                                        label="Kuvaus kulunvalvontajärjestelmästä"
                                        value={accessDescription}
                                        onChange={(e) => setAccessDescription(e.target.value)}
                                        multiline
                                        fullWidth
                                        rows={4}
                                    />
                                )}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked={false}
                                            value={camera}
                                            onChange={(e) => setCamera(e.target.checked)}
                                        />
                                    }
                                    label="Rekisterikilpitunnistus"
                                />
                                {camera && (
                                    <TextField
                                        label="Kuvaus rekisterikilpitunnistuksesta"
                                        value={cameraDescription}
                                        onChange={(e) => setCameraDescription(e.target.value)}
                                        multiline
                                        rows={4}
                                    />
                                )}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked={false}
                                            value={keymanagement}
                                            onChange={(e) => setKeymanagement(e.target.checked)}
                                        />
                                    }
                                    label="Avainlista"
                                />
                                {keymanagement && (
                                    <TextField
                                        label="Kuvaus avainlistasta"
                                        value={keymanagementDescription}
                                        onChange={(e) => setKeymanagementDescription(e.target.value)}
                                        multiline
                                        rows={4}
                                    />
                                )}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked={false}
                                            value={booking}
                                            onChange={(e) => setBooking(e.target.checked)}
                                        />
                                    }
                                    label="Varausjärjestelmä"
                                />
                                {booking && (
                                    <TextField
                                        label="Kuvaus varausjärjestelmästä"
                                        value={bookingDescription}
                                        onChange={(e) => setBookingDescription(e.target.value)}
                                        multiline
                                        rows={4}
                                    />
                                )}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked={false}
                                            value={charging}
                                            onChange={(e) => setCharging(e.target.checked)}
                                        />
                                    }
                                    label="Sähköauton latausjärjestelmä"
                                />
                                {charging && (
                                    <TextField
                                        label="Kuvaus sähköauton latausjärjestelmästä"
                                        value={chargingDescription}
                                        onChange={(e) => setChargingDescription(e.target.value)}
                                        multiline
                                        rows={4}
                                    />
                                )}
                            </FormGroup>
                        </ListItem>

                    </>}
                    <ListItem>
                        <Button onClick={() => setSummary(!summary)} variant="contained" sx={{ backgroundColor: !summary ? ColorScheme.primary : ColorScheme.red, marginY: "15px", width: "100%" }}><Typography textTransform={"none"}>{summary ? "Poista saate" : "Lisää saate"}</Typography></Button>
                    </ListItem>
                    <ListItem>
                        <TextField
                            size="small"
                            label={<Typography>Asiakas</Typography>}
                            style={{ width: "100%" }}
                            value={customer}
                            onChange={(newValue) =>
                                setCustomer(newValue.target.value)
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                            size="small"
                            label={<Typography>Asiakkaan katuosoite</Typography>}
                            style={{ width: "100%" }}
                            value={address}
                            onChange={(newValue) =>
                                setAddress(newValue.target.value)
                            }
                        />

                    </ListItem>
                    <ListItem>
                        <TextField
                            size="small"
                            label={<Typography>Postinumero ja postitoimipaikka</Typography>}
                            style={{ width: "100%" }}
                            value={postalCode}
                            onChange={(newValue) =>
                                setPostalCode(newValue.target.value)
                            }
                        />

                    </ListItem>
                    <ListItem sx={{ alignItems: "center", flexDirection: "column" }}>
                        {Array.from({ length: productAmount }, (_, index) => (
                            <Box sx={{ width: "100%", backgroundColor: ColorScheme.lightGrey, my: "10px", borderRadius: "4px", border: "1px solid", borderColor: ColorScheme.border, p: "10px", position: "relative" }} key={index}>
                                <IconButton
                                    sx={{
                                        padding: 0,
                                        minWidth: 0,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "20px",
                                        width: "20px",
                                        position: "absolute",
                                        top: "-10px",
                                        right: "-10px",
                                        backgroundColor: ColorScheme.red,
                                        color: ColorScheme.white,
                                        borderRadius: "50%",
                                        zIndex: 1
                                    }}
                                    onClick={() => handleProductAmount(0)}>
                                    <RemoveIcon height={"18px"} stroke={ColorScheme.white} />
                                </IconButton>
                                <Box key={index} sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                    <TextField
                                        size="small"
                                        label={<Typography>Tuote</Typography>}
                                        style={{ width: "50%" }}
                                        value={products[index].name}
                                        onChange={(newValue) =>
                                            handleProducts(index, "name", newValue.target.value)
                                        }
                                    />
                                    <TextField
                                        size="small"
                                        label={<Typography>Määrä</Typography>}
                                        style={{ width: "20%" }}
                                        value={products[index].amount}
                                        onChange={(newValue) =>
                                            handleProducts(index, "amount", newValue.target.value)
                                        }
                                    />
                                    <TextField
                                        size="small"
                                        label={<Typography>Hinta</Typography>}
                                        style={{ width: "20%" }}
                                        value={products[index].price}
                                        onChange={(newValue) =>
                                            handleProducts(index, "price", newValue.target.value)
                                        }
                                    />

                                </Box>
                            </Box>))}
                    </ListItem>
                    <ListItem sx={{ justifyContent: "center" }}>
                        <Button onClick={() => handleProductAmount(1)} variant="contained" sx={{ backgroundColor: ColorScheme.primary, marginLeft: "2px" }}><Typography textTransform={"none"}>{"Lisää tuote"}</Typography></Button>
                    </ListItem>
                    <ListItem sx={{ alignItems: "center", flexDirection: "column" }}>
                        {Array.from({ length: serviceAmount }, (_, index) => (
                            <Box sx={{ width: "100%", backgroundColor: ColorScheme.lightGrey, my: "10px", borderRadius: "4px", border: "1px solid", borderColor: ColorScheme.border, p: "10px", position: "relative" }} key={index}>
                                <IconButton
                                    sx={{
                                        padding: 0,
                                        minWidth: 0,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "20px",
                                        width: "20px",
                                        position: "absolute",
                                        top: "-10px",
                                        right: "-10px",
                                        backgroundColor: ColorScheme.red,
                                        color: ColorScheme.white,
                                        borderRadius: "50%",
                                        zIndex: 1
                                    }}
                                    onClick={() => handleServiceAmount(0)}>
                                    <RemoveIcon height={"18px"} stroke={ColorScheme.white} />
                                </IconButton>
                                <Box key={index} sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                    <TextField
                                        size="small"
                                        label={<Typography>Palvelu</Typography>}
                                        style={{ width: "50%" }}
                                        value={services[index].name}
                                        onChange={(newValue) => handleServices(index, "name", newValue.target.value)}
                                    />
                                    <TextField
                                        size="small"
                                        label={<Typography>Määrä</Typography>}
                                        style={{ width: "20%" }}
                                        value={services[index].amount}
                                        onChange={(newValue) =>
                                            handleServices(index, "amount", newValue.target.value)
                                        }
                                    />
                                    <TextField
                                        size="small"
                                        label={<Typography>Hinta</Typography>}
                                        style={{ width: "20%" }}
                                        value={services[index].price}
                                        onChange={(newValue) => handleServices(index, "price", newValue.target.value)}
                                    />
                                </Box>
                            </Box>))}
                    </ListItem>
                    <ListItem sx={{ justifyContent: "center", marginBottom: "10px" }}>
                        <Button onClick={() => handleServiceAmount(1)} variant="contained" sx={{ backgroundColor: ColorScheme.primary, marginLeft: "2px" }}><Typography textTransform={"none"}>{"Lisää palvelu"}</Typography></Button>
                    </ListItem>
                    <ListItem>
                        <TextField
                            size="small"
                            label={<Typography>Tarjouksen laatija</Typography>}
                            style={{ width: "100%" }}
                            value={contactName}
                            onChange={(newValue) =>
                                setContactName(newValue.target.value)
                            }
                        />

                    </ListItem>
                    <ListItem>
                        <TextField
                            size="small"
                            label={<Typography>Tarjouksen laatijan puhelin</Typography>}
                            style={{ width: "100%" }}
                            value={contactPhone}
                            onChange={(newValue) =>
                                setContactPhone(newValue.target.value)
                            }
                        />
                    </ListItem>

                </List>
            </Box>
            <Box sx={{ justifyContent: "center", display: "flex", width: isTabletOrBelow ? "100%" : "49%", height: "calc(100vh - 115px)" }}>
                <PDFViewer style={{ width: '100%', height: "100%" }}>
                    <PdfDocument
                        customer={customer}
                        street={address}
                        city={postalCode}
                        products={products}
                        services={services}
                        contactName={contactName}
                        contactPhone={contactPhone}
                        letterTitle={letterTitle}
                        letterDescription={letterDescription}
                        access={access}
                        booking={booking}
                        charging={charging}
                        keymanagement={keymanagement}
                        summary={summary}
                        camera={camera}
                        AccessText={accessDescription}
                        KeyText={keymanagementDescription}
                        BookText={bookingDescription}
                        ChargeText={chargingDescription}
                        CameraText={cameraDescription} />
                </PDFViewer>
            </Box>

        </Box>
    );
}

export default Offer;
