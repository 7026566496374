import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, useMediaQuery } from '@mui/material';

const SixFigureInput = ({ value, onChange }) => {
    const [inputValues, setInputValues] = useState(Array(6).fill(''));
    const inputRefs = useRef([]);

    const isTabletOrBelow = useMediaQuery('(max-width:768px)');

    useEffect(() => {
        if (value.length === 6) {
            setInputValues(value.split(''));
        }
    }, [value]);

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !inputValues[index] && index > 0) {
            // Move focus to the previous input box if backspace is pressed and current box is empty
            inputRefs.current[index - 1].focus();
        }
    };

    const handleChange = (e, index) => {
        const newValue = e.target.value;
        if (/^\d?$/.test(newValue)) {
            const newInputValues = [...inputValues];
            newInputValues[index] = newValue;
            setInputValues(newInputValues);
            onChange(newInputValues.join(''));

            // Move focus to the next input box
            if (newValue && index < 5) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    return (
        <Box display="flex" justifyContent="space-between" sx={{ my: "20px", width: isTabletOrBelow ? "90%" : "40%" }}>
            {inputValues.map((val, index) => (
                <TextField
                    key={index}
                    value={val}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    variant="outlined"
                    inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                    style={{ width: '3rem', margin: '0 0.5rem' }}
                    inputRef={(el) => (inputRefs.current[index] = el)}
                />
            ))}
        </Box>
    );
};

export default SixFigureInput;