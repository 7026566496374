const ColorScheme = {
    primary: "#264166",
    background: "#0F1A29",
    white: "#FFFFFF",
    black: "#323232",
    green: "#649F49",
    red: "#9F4949",
    yellow: "#C6C24F",
    lightGrey: "#FCFCFC",
    border: "#E9E9E9",
    search: "#F3F3F3",
    red_opacity: "rgba(159, 73, 73, 0.1)",
    green_opacity: "rgba(100, 159, 73, 0.1)",
};

export default ColorScheme;