

import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import Fontsrc from '../../Utils/fonts/Sofia_Sans.ttf';
import Logo from '../../Assets/logo-3 3.png'
import LogoBall from '../../Assets/Tolotech_logo_white_new.png'

Font.register({ family: 'Sofia Sans', src: Fontsrc })

const styles = StyleSheet.create({
    page: { flexDirection: 'column', backgroundColor: '#FFFFFF', padding: 50, justifyContent: "space-between", display: "flex" },
    section: { flexDirection: "row", display: "flex", justifyContent: "space-between" },
    letter_section: { display: "flex", justifyContent: "space-between", alignItems: "center" },
    title: { fontSize: 16, marginBottom: 10, fontFamily: 'Sofia Sans' },
    text: { fontSize: 12, color: "#202020", fontFamily: 'Sofia Sans' },
    footer: { fontSize: 12, color: "#FFFFFC", fontFamily: 'Sofia Sans' },
});

//const AccessText = "ToloAccess-palvelulla on mahdollista hallinnoida monenlaisten kohteiden sisäänkäyntejä ja muita ovia. Oven avaamiseen löytyy useita keinoja, joista suosituimmat ovat mobiilisovellus, rekisteritunnuskamera, puhelinsoitto ja näppäimistö. Järjestelmä on kokonaisuudessaan kotimaista tuotantoa aina ohjelmistoista rautaan asti. Palvelu sisältää hallinnointikäyttöliittymän, josta löytyvät myös ohjeet järjestelmän käyttämistä varten. Halutessasi voit liittää ToloAccess-palveluun myös varausjärjestelmämme tai sähköauton latausinfran."
//const KeyText = "Hallintapaneeliin on nyt lisätty Avainlista-ominaisuus, minkä ansiosta käyttäjälistan ajantasalla pitäminen on helppoa ja turvallista. Kohteen hallinnoija ylläpitää käyttäjälistaa, jonka perusteella Tolotech Oy lisää käyttäjille oikeat kulkuoikeudet viimeistään seuraavaksi päiväksi. Tolotech Oy vastaa kulkuoikeuksista Turvallisuusalan elinkeinoluvan haltijana (TU/2018/2094). Avainlistaa on mahdollista tarkastella ja muokata hallintapaneelissa."
//const BookText = "Tolotechin kehittämän varausjärjestelmän avulla voit organisoida kohteesi tilojen ja aikojen varauksia sekä seurata käyttöastetta helposti. ToloBook pitää varausominaisuuden lisäksi sisällään myös tuotteiden, kurssien ja kampanjoiden organisoinnin. Järjestelmää hallitaan niin ikään web-sovelluksemme kautta ja voit halutessasi liittää esimerkiksi kulunvalvontamme osaksi järjestelmäkokonaisuutta. Tällöin kulkuoikeudet ja varaukset pelaavat saumattomasti yhteen säästäen samalla hallinnoijan aikaa ja vaivaa."
//const ChargeText = "Tarjoamme nyt myös sähköautojen latausta osana laajaa palvelukokonaisuuttamme. ToloCharge kattaa latauslaitteiston ja -ohjelmiston lisäksi kohteen kartoituksen ja laitteiston asennuksen. Järjestelmä hyödyntää OCPP-rajapintaa, mikä tarkoittaa sitä, että kehittämämme ohjelmistokokonaisuus toimii useamman laitetoimittajan laitteiston kanssa. Toimitamme asiakkaalle tämän tarpeisiin sopivan latauslaitteiston sekä latauspalvelun, joka mahdollistaa latauksen maksamisen mobiilisovelluksella."
//const CameraText = "Rekisterikilpitunnistus osana kulunvalvontajärjestelmäämme mahdollistaa kohteen sisäänkäyntien ja porttien automaattisen avaamisen rekisterikilven perusteella. Rekisterikilpitunnistus ei sulje pois järjestelmän muita avaustapoja, vaan sisäänkäynnin voi edelleen avata sekä puhelinsoittoa että mobiilisovellusta käyttämällä. Kaikki rekisterikilpitunnistukseen tarvittavat laitteet ja ohjelmistot toimitetaan asiakkaalle valmiiksi asennettuina ja konfiguroituina."

const PdfDocument = ({ customer, street, city, products, services, contactName, contactPhone, letterTitle, letterDescription, access, booking, charging, keymanagement, summary, camera, AccessText, KeyText, BookText, ChargeText, CameraText }) => (
    <Document title={"Tarjous"}>
        {summary && <Page size="A4" style={styles.page}>

            <View style={[styles.section, { alignItems: "center" }]}>
                <View style={{ width: "30%", alignItems: "center" }}>
                    <Image
                        source={Logo}
                    />
                </View>

                <View style={{ flexDirection: "row" }}>
                    <Text style={[styles.text, { marginRight: "30px" }]}>LUOTTAMUKSELLINEN</Text>
                    <Text style={styles.text}>{new Date().toLocaleDateString()}</Text>
                    <Text style={styles.text}> SAATE</Text>
                </View>

            </View>

            <View style={styles.letter_section}>
                <View style={{ width: "60%" }}>
                    <Text style={[styles.title, { textAlign: "center", marginBottom: 20 }]}>{letterTitle}</Text>
                </View>

                <Text style={[styles.text, { textAlign: "justify" }]}>{letterDescription}</Text>
            </View>

            <View style={{ height: "1px", width: "100%", backgroundColor: "#264166", marginVertical: 2 }} />

            <View>
                {access && <View style={{ marginBottom: 10 }}>
                    <Text style={[styles.title, { fontSize: 14 }]}>Kulunvalvontajärjestelmä</Text>
                    <Text style={[styles.text, { textAlign: "justify", fontSize: 10 }]}>{AccessText}</Text>
                </View>}
                {camera && <View style={{ marginVertical: 10 }}>
                    <Text style={[styles.title, { fontSize: 14 }]}>Rekisterikilpitunnistus</Text>
                    <Text style={[styles.text, { textAlign: "justify", fontSize: 10 }]}>{CameraText}</Text>
                </View>}
                {keymanagement && <View style={{ marginVertical: 10 }}>
                    <Text style={[styles.title, { fontSize: 14 }]}>Avainlista</Text>
                    <Text style={[styles.text, { textAlign: "justify", fontSize: 10 }]}>{KeyText}</Text>
                </View>}
                {booking && <View style={{ marginVertical: 10 }}>
                    <Text style={[styles.title, { fontSize: 14 }]}>Varausjärjestelmä</Text>
                    <Text style={[styles.text, { textAlign: "justify", fontSize: 10 }]}>{BookText}</Text>
                </View>}
                {charging && <View style={{ marginTop: 10 }}>
                    <Text style={[styles.title, { fontSize: 14 }]}>Sähköauton latausjärjestelmä</Text>
                    <Text style={[styles.text, { textAlign: "justify", fontSize: 10 }]}>{ChargeText}</Text>
                </View>}
            </View>

            <View style={{ height: "50px", width: "121%", backgroundColor: "#264166", marginBottom: -50, marginHorizontal: -50, paddingHorizontal: 50, alignItems: "center", flexDirection: "row", justifyContent: "space-between" }} >
                <View style={{ width: "5%" }}>
                    <Image
                        source={LogoBall}
                    />
                </View>
                <Text style={styles.footer}>Myynti: myynti@tolotech.fi</Text>
                <Text style={styles.footer}>Huolto: huolto@tolotech.fi</Text>
                <Text style={styles.footer}>www.tolotech.fi</Text>
            </View>

        </Page>}
        <Page size="A4" style={styles.page}>
            <View style={[styles.section, { alignItems: "center" }]}>
                <View style={{ width: "30%", alignItems: "center" }}>
                    <Image
                        source={Logo}
                    />
                </View>

                <View style={{ flexDirection: "row" }}>
                    <Text style={[styles.text, { marginRight: "30px" }]}>LUOTTAMUKSELLINEN</Text>
                    <Text style={styles.text}>{new Date().toLocaleDateString()}</Text>
                    <Text style={styles.text}> TARJOUS</Text>
                </View>

            </View>
            <View style={[styles.section, { marginVertical: 40 }]}>
                <View style={{ width: "50%" }}>
                    <Text style={[styles.text, { marginBottom: "2px" }]}>{customer}</Text>
                    <Text style={[styles.text, { marginVertical: "2px" }]}>{street}</Text>
                    <Text style={[styles.text, { marginTop: "2px" }]}>{city}</Text>
                </View>
            </View>
            {products.length > 0 && <View style={styles.section}>
                <View style={{ width: "20%" }}>
                    <Text style={styles.text}>Tuotteet:</Text>
                </View>
                <View style={{ width: "80%" }}>
                    {products != undefined && products.map((product, index) => {
                        return (
                            <View key={index} style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                <View style={{ width: "65%" }}>
                                    <Text style={styles.text}>{product.name}</Text>
                                </View>
                                <View style={{ width: "15%" }}>
                                    <Text style={styles.text}>{product.amount}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.text}>{product.price}</Text>
                                </View>



                            </View>
                        )
                    })}

                </View>
            </View>}
            {services.length > 0 && <View style={styles.section}>

                <View style={{ width: "20%" }}>
                    <Text style={styles.text}>Palvelut:</Text>
                </View>
                <View style={{ width: "80%" }}>
                    {services != undefined && services.map((service, index) => {
                        return (
                            <View key={index} style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                <View style={{ width: "65%" }}>
                                    <Text style={styles.text}>{service.name}</Text>
                                </View>
                                <View style={{ width: "15%" }}>
                                    <Text style={styles.text}>{service.amount}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.text}>{service.price}</Text>
                                </View>



                            </View>
                        )
                    })}
                </View>
            </View>}
            <View style={{ height: "1px", width: "100%", backgroundColor: "#264166", marginVertical: 5 }} />
            <View style={styles.section}>
                <View style={{ width: "20%" }}>
                    <Text style={[styles.text, { marginBottom: "2px" }]}>Asennus: </Text>
                    <Text style={[styles.text, { marginVertical: "2px" }]}>Huolto: </Text>
                    <Text style={[styles.text, { marginVertical: "2px" }]}>Takuu:</Text>
                    <Text style={[styles.text, { marginVertical: "2px" }]}>Tuki: </Text>
                    <Text style={[styles.text, { marginVertical: "2px" }]}>Maksuehdot: </Text>
                    <Text style={[styles.text, { marginTop: "2px" }]}>Toimitus:</Text>
                </View>
                <View style={{ width: "80%" }}>
                    <Text style={[styles.text, { marginBottom: "2px" }]}>Asennuksen tuntihinta on 89 € ja kilometrikorvaus 1,50 €.</Text>
                    <Text style={[styles.text, { marginVertical: "2px" }]}>Huoltopäivystykset suomeksi/englanniksi.</Text>
                    <Text style={[styles.text, { marginVertical: "2px" }]}>Laitteisto 24 kk.</Text>
                    <Text style={[styles.text, { marginVertical: "2px" }]}>Käytönaikainen opastus ja tuki suomeksi/englanniksi.</Text>
                    <Text style={[styles.text, { marginVertical: "2px" }]}>Tuotteet ja palvelut 14 pv netto. Kaikki hinnat alv 0%. </Text>
                    <Text style={[styles.text, { marginTop: "2px" }]}>Toimitus sopimuksen mukaan.</Text>
                </View>

            </View>
            <View style={{ height: "1px", width: "100%", backgroundColor: "#264166", marginVertical: 5 }} />
            <View style={styles.section}>
                <View style={{ width: "20%" }}>
                </View>
                <View style={{ width: "80%" }}>
                    <Text style={styles.text}>Tarjouksen voimassaoloaika on 2 kuukautta tarjouksen jättämispäivämäärästä.</Text>
                    <Text style={styles.text}> </Text>
                    <Text style={styles.text}>Ystävällisesti</Text>
                </View>

            </View>
            <View style={[styles.section, { marginTop: 40 }]}>
                <View style={{ width: "20%" }}>
                </View>
                <View style={{ width: "80%" }}>
                    <Text style={styles.text}>{contactName}, {contactPhone}</Text>
                </View>
            </View>
            <View style={{ height: "50px", width: "121%", backgroundColor: "#264166", marginBottom: -50, marginHorizontal: -50, paddingHorizontal: 50, alignItems: "center", flexDirection: "row", justifyContent: "space-between" }} >
                <View style={{ width: "5%" }}>
                    <Image
                        source={LogoBall}
                    />
                </View>
                <Text style={styles.footer}>Myynti: myynti@tolotech.fi</Text>
                <Text style={styles.footer}>Huolto: huolto@tolotech.fi</Text>
                <Text style={styles.footer}>www.tolotech.fi</Text>
            </View>

        </Page>


    </Document>
);

export default PdfDocument;