// src/App.js
import React from 'react';
import { UserProvider } from './Utils/UserContext';
import { ApiProvider } from './Utils/ApiContext';
import { BrowserRouter, Router } from 'react-router-dom';
import Navbar from './Components/Main/Navbar';
import AppRouter from './Utils/Router';
import useAuthRedirect from './Utils/UseAuthRedirect';

const App = () => {
  return (
    <UserProvider>
      <BrowserRouter>
        <ApiProvider>
          <UserProvider>
            <AuthWrapper>
              <Navbar />
              <AppRouter />
            </AuthWrapper>
          </UserProvider>
        </ApiProvider>
      </BrowserRouter>
    </UserProvider>
  );
};

const AuthWrapper = ({ children }) => {
  useAuthRedirect();
  return <>{children}</>;
};

export default App;
