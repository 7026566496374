import { createTheme } from '@mui/material/styles';
import ColorScheme from './ColorScheme';

// Create a theme instance.
const Theme = createTheme({
    typography: {
        fontFamily: 'Sofia Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        h1: {
            fontSize: '1.25rem', // 20px
            fontWeight: 800,
        },
        h2: {
            fontSize: '1.125rem', // 18px
            fontWeight: 800,
        },
        h3: {
            fontSize: '1rem', // 16px
            fontWeight: 800,
        },
        h4: {
            fontSize: '0.875rem', // 14px
            fontWeight: 800,
        },
        h5: {
            fontSize: '0.75rem', // 12px
            fontWeight: 800,
        },
        h6: {
            fontSize: '0.625rem', // 10px
            fontWeight: 800,
        },
        body1: {
            fontSize: '0.75rem', // 10px
            fontWeight: 400,
        },
        body2: {
            fontSize: '0.625rem', // 10px
            fontWeight: 400,
        },
        subtitle1: {
            fontSize: '0.625rem', // 10px
            fontWeight: 500,
        },
        subtitle2: {
            fontSize: '0.5rem', // 8px
            fontWeight: 500,
        },
        caption: {
            fontSize: '0.5rem', // 8px
            fontWeight: 400,
        },
        overline: {
            fontSize: '0.5rem', // 8px
            fontWeight: 400,
            textTransform: 'uppercase',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    // Hide scrollbar but allow scrolling
                    overflow: 'auto',
                    scrollbarWidth: 'none', /* Firefox */
                    '&::-webkit-scrollbar': {
                        display: 'none', /* Chrome, Safari, Opera */
                    },
                },
                '.hide-scrollbar': {
                    overflowY: 'scroll',
                    scrollbarWidth: 'none', /* Firefox */
                    '&::-webkit-scrollbar': {
                        display: 'none', /* Chrome, Safari, and Opera */
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-focused': {
                        color: '#264166', // Custom color when focused
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#264166', // Custom border color when focused
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "2em",
                    backgroundColor: ColorScheme.lightGrey,
                    border: "1px solid",
                    borderColor: ColorScheme.border
                }
            }
        }
    },
});

export default Theme;